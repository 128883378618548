export const ddsColors = {
  black: "#000000",
  white: "#ffffff",
  apple_10: "#ffede5",
  apple_20: "#ffd6c7",
  apple_30: "#ffb6a3",
  apple_40: "#fd7c62",
  apple_50: "#f9513e",
  apple_60: "#d92218",
  apple_70: "#880c11",
  apple_80: "#5d0909",
  apple_90: "#390909",
  cloud_10: "#f1f3f8",
  cloud_20: "#e2e4f0",
  cloud_30: "#cacee8",
  cloud_40: "#aab0d8",
  cloud_50: "#8689b8",
  cloud_60: "#5d5d8f",
  cloud_70: "#403f6c",
  cloud_80: "#2c2a50",
  cloud_90: "#1a192d",
  fox_10: "#ffeedb",
  fox_20: "#ffdfb3",
  fox_30: "#ffc086",
  fox_40: "#fb9246",
  fox_50: "#f15c00",
  fox_60: "#b9410e",
  fox_70: "#80260b",
  fox_80: "#541708",
  fox_90: "#381008",
  frog_10: "#e0fff5",
  frog_20: "#c7ffea",
  frog_30: "#87f0c4",
  frog_40: "#46cd8f",
  frog_50: "#269f62",
  frog_60: "#13803a",
  frog_70: "#0a5e1c",
  frog_80: "#07430b",
  frog_90: "#0b3308",
  grape_10: "#f6eeff",
  grape_20: "#f0d9ff",
  grape_30: "#dfbdff",
  grape_40: "#c399ff",
  grape_50: "#9d70ff",
  grape_60: "#7d40ff",
  grape_70: "#612bd4",
  grape_80: "#41248f",
  grape_90: "#1f1551",
  gradiant_blue: "#0099DD",
  ocean_10: "#ebf2ff",
  ocean_20: "#cce3ff",
  ocean_30: "#add2ff",
  ocean_40: "#80b2ff",
  ocean_50: "#3a7fe7",
  ocean_60: "#1b63c0",
  ocean_70: "#195294",
  ocean_80: "#0e3c67",
  ocean_90: "#0b2e47",
  pig_10: "#ffe8f7",
  pig_20: "#ffd6ee",
  pig_30: "#ffbde1",
  pig_40: "#ff8fd0",
  pig_50: "#f86dc0",
  pig_60: "#c34193",
  pig_70: "#8f2169",
  pig_80: "#64104a",
  pig_90: "#460935",
  sky_10: "#ebf9ff",
  sky_20: "#ccf4ff",
  sky_30: "#adedff",
  sky_40: "#80ddff",
  sky_50: "#49b4d8",
  sky_60: "#1b9ac0",
  sky_70: "#197c94",
  sky_80: "#0e5a67",
  sky_90: "#0b4247",
  sun_10: "#fffddf",
  sun_20: "#fff7b2",
  sun_30: "#ffee80",
  sun_40: "#ffd633",
  sun_50: "#ffb800",
  sun_60: "#c97c01",
  sun_70: "#935202",
  sun_80: "#673305",
  sun_90: "#472109",
} as const;

export const alphaCodes = {
  alpha_100: "FF",
  alpha_90: "E6",
  alpha_80: "CC",
  alpha_70: "B3",
  alpha_60: "99",
  alpha_50: "80",
  alpha_45: "73",
  alpha_40: "66",
  alpha_30: "4D",
  alpha_25: "40",
  alpha_20: "33",
  alpha_15: "26",
  alpha_10: "1A",
} as const;
