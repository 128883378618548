import uniqueId from "lodash/uniqueId";
import { useEffect, useRef, useState } from "react";
import { LabelFormElement } from "../..";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { invalidTextFieldStyle, textFieldStyles, TEXT_FIELD_HEIGHT } from "./TextField";
import BodyText from "./typography/bodyText";

export type TextAreaProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> &
  TextAreaConditionalProps &
  TextAreaConstantProps;

type TextAreaConditionalProps =
  | {
      label?: never;
      ["aria-label"]: string;
    }
  | {
      label: string | React.ReactElement;
      ["aria-label"]?: string;
    };

type TextAreaConstantProps = {
  valid?: boolean;
  resize?: "none" | "both" | "horizontal" | "vertical";
  validationMessage?: string;
  message?: string;
  onChange?: (value: string) => void;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]?: string;
  minHeight?: number;
  maxHeight?: number;
  fixedHeight?: number;
  autoFocus?: boolean;
};

export function TextArea({
  valid = true,
  label,
  validationMessage,
  message,
  onChange,
  ["data-name"]: dataName,
  className,
  id,
  resize = "none",
  fixedHeight,
  minHeight = TEXT_FIELD_HEIGHT,
  maxHeight = TEXT_FIELD_HEIGHT * 2,
  autoFocus = false,
  children,
  ...props
}: TextAreaProps) {
  const [inputId] = useState(id || uniqueId("TextAreaId"));
  const [touched, setTouched] = useState(false);
  const descriptionId = `${inputId}-description`;
  const alertId = `${inputId}-alert`;
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setHeight(event.target);
    if (onChange) {
      onChange(value);
    }
  };

  const textareaElement = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaElement.current && autoFocus) {
      textareaElement.current.focus();
    }
  }, [autoFocus]);

  const setHeight = (target: HTMLElement | null) => {
    if (!target) {
      return;
    }

    // this is responsible for making the textarea's height grow with the text inside it.
    if (!fixedHeight) {
      target.style.height = `${minHeight || TEXT_FIELD_HEIGHT}px`;
      target.style.height = `${target.scrollHeight}px`;
    }
    if (!props.value) {
      target.style.height = `${minHeight || TEXT_FIELD_HEIGHT}px`;
    }
  };

  // this is necessary to return the input to it's original size once the value is reset to ""

  useEffect(() => {
    const target = document.getElementById(inputId);
    setHeight(target);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, inputId, minHeight]);

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "xs",
        ":focus-within": {
          [`#${descriptionId}`]: valid || (!props.value && !touched) ? visibleStyles : {},
        },
      }}
      onBlur={() => {
        if (!touched) setTouched(true);
      }}
      className={className}
    >
      {label && <LabelFormElement htmlFor={inputId} labelText={label} />}

      <div sx={{ position: "relative", display: "flex", alignItems: "center" }}>
        <textarea
          ref={textareaElement}
          onChange={handleChange}
          data-name={dataName}
          sx={{
            ...textFieldStyles,
            ...(!valid && touched ? invalidTextFieldStyle : {}),
            resize,
            minHeight: fixedHeight || minHeight,
            maxHeight: fixedHeight || maxHeight,
            ...scrollbarStyles,
          }}
          id={inputId}
          {...props}
        />
        {children}
      </div>
      {validationMessage && !valid && touched && (
        <BodyText level={2} id={alertId} kind="danger">
          {validationMessage}
        </BodyText>
      )}
      {message && (
        <BodyText level={2} id={descriptionId} kind="tertiary" sx={{ paddingLeft: "xs", ...invisibleStyles }}>
          {message}
        </BodyText>
      )}
    </div>
  );
}

/**
 * it is important that this text is always rendered to help with context for screen readers
 */
const invisibleStyles: ThemeUIStyleObject = {
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
  opacity: 0,
};
const visibleStyles: ThemeUIStyleObject = {
  position: "relative",
  left: "0",
  top: "0",
  width: "fit-content",
  height: "fit-content",
  overflow: "hidden",
  opacity: 1,
};
const scrollbarStyles: ThemeUIStyleObject = {
  // Firefox
  scrollbarWidth: "auto",
  scrollbarColor: "#AAB0D8 transparent",

  /* Chrome, Edge, and Safari */
  "&::-webkit-scrollbar": {
    width: "20px",
  },

  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: "dt_radius_s",
    boxShadow: RAW_cssValue("inset 0 0 10px 10px"),
    color: "dt_content_tertiary",
    border: RAW_cssValue("solid 6px transparent"),
  },
};
