import { ThemeUIStyleObject } from "../../stylingLib";
import { BaseText, BaseTextProps } from "./baseText";

// eslint-disable-next-line react-refresh/only-export-components
export const labelTextLevels = [1, 2] as const;
export type LabelTextLevelsType = (typeof labelTextLevels)[number];

export type LabelTextProps = {
  level?: LabelTextLevelsType;
} & BaseTextProps;

export function LabelText({ level = 1, ...props }: LabelTextProps) {
  return <BaseText sx={labelTextStyles[level]} {...props} />;
}

export default LabelText;

// eslint-disable-next-line react-refresh/only-export-components
export const labelTextStyles: Record<LabelTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: "dt_bold",
  },
  2: {
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: "dt_bold",
    letterSpacing: "0.2px",
  },
};
