import { ThemeUIStyleObject } from "../../stylingLib";
import { BaseText, BaseTextProps } from "./baseText";

// eslint-disable-next-line react-refresh/only-export-components
export const headlineTextLevels = [1, 2, 3] as const;
export type HeadlineTextLevelsType = (typeof headlineTextLevels)[number];

export type HeadlineTextProps = {
  level?: HeadlineTextLevelsType;
  // You need to specify the tag type to use this component, it should really be an h#-tag
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & BaseTextProps;

export function HeadlineText({ level = 1, ...props }: HeadlineTextProps) {
  return <BaseText sx={headlineTextStyles[level]} {...props} />;
}

export default HeadlineText;

// eslint-disable-next-line react-refresh/only-export-components
export const headlineTextStyles: Record<HeadlineTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.1px",
  },
  2: {
    fontSize: "23px",
    lineHeight: "28px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.1px",
  },
  3: {
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.1px",
  },
};
