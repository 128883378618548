/**
 * This is a general function that can be used in any tab element.
 * Just remember to make sure that the tablist element only contains tabs and nothing else
 */
// eslint-disable-next-line react-refresh/only-export-components
export function handleTabChange(event: React.KeyboardEvent, currentTabId: string) {
  const currentElement = document.getElementById(currentTabId);

  if (!currentElement) return;

  const parentElement = currentElement.parentNode;
  let target;

  if (event.key === "ArrowRight" && currentElement !== parentElement?.lastChild) {
    target = currentElement.nextSibling as HTMLElement;
  } else if (event.key === "ArrowLeft" && currentElement !== parentElement?.firstChild) {
    target = currentElement.previousSibling as HTMLElement;
  }

  target?.click();
  target?.focus();
}
