import { useState } from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { handleTabChange } from "../../nessie/components/tabs";

export type TabsGroupOption<T> = {
  value: T;
  text: string;
  id: string;
  tabPanelId: string;
};

type TabsGroupProps<T> = {
  value: T;
  options: TabsGroupOption<T>[];
  optionStyles?: ThemeUIStyleObject[];
  onChange?: (selected: T) => void;
  focusOnSelected?: boolean;
  className?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};

/**
 *  This TabsGroup component should only be used when we need to create custom tabs.
 *  For standard tabs, use the TabbedSection nessie component.
 */

// eslint-disable-next-line comma-spacing
const TabsGroup = <T,>({
  value,
  options = [],
  optionStyles = [],
  onChange,
  focusOnSelected,
  className,
  "data-name": dataName,
}: TabsGroupProps<T>) => {
  const [currentId, setCurrentId] = useState("");
  const _handleClick = (optionValue: T) => {
    if (!onChange) {
      return;
    }

    onChange(optionValue);
  };

  return (
    <div
      sx={{ display: "flex" }}
      role={"tablist"}
      tabIndex={0}
      onKeyDown={(event) => handleTabChange(event, currentId)}
      className={className}
    >
      {options.map((option, index) => (
        <button
          key={option.id}
          role="tab"
          sx={{
            ...buttonBaseStyles,
            ...optionStyles[index],
          }}
          tabIndex={option.value === value ? 0 : -1}
          id={option.id}
          aria-controls={option.tabPanelId}
          onClick={() => _handleClick(option.value)}
          aria-selected={option.value === value}
          onFocus={() => setCurrentId(option.id)}
          // this optional autofocus is necessary because the WholeClassHeader componenent rerenders this component
          // on tab change making it lose the focus
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focusOnSelected && option.value === value}
          data-name={`${dataName}:tab:${option.value}`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default TabsGroup;

const buttonBaseStyles: ThemeUIStyleObject = {
  userSelect: "none",
  display: "inline-block",
  flex: "1 1 0%",
  border: "none",
  fontWeight: 600,
  background: "transparent",
  borderColor: "dt_border_active",
  borderStyle: "solid",
  color: "dt_content_accent",
  padding: "dt_m",
  paddingTop: "dt_s",
  paddingBottom: "dt_s",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap" as const,
  borderTopWidth: "1px",
  borderRightWidth: "1px",
  borderLeftWidth: 0,
  borderBottomWidth: "1px",
  "&[aria-selected=true]": { color: "dt_content_light", backgroundColor: "dt_content_accent" },
  ":first-child": {
    borderTopWidth: "1px",
    borderLeftWidth: "1px",
    borderBottomWidth: "1px",
    borderRightWidth: "1px",
    borderTopLeftRadius: "dt_radius_s",
    borderBottomLeftRadius: "dt_radius_s",
  },
  ":last-child": {
    borderTopWidth: "1px",
    borderLeftWidth: 0,
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    borderTopRightRadius: "dt_radius_s",
    borderBottomRightRadius: "dt_radius_s",
  },
};
