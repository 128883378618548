/** useOnFirstRender: is a hook that allows you to run a callback when the component is first rendered.
 * Preferred to useEffect per https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application.
 * If you are using this with side effects, use useSideEffectOnMount instead
 * @param callback - The callback to run when the component is mounted.
 */
import { useRef } from "react";

export default function useOnFirstRender(callback: () => void) {
  const firstRun = useRef<boolean>(true);
  if (firstRun.current) {
    firstRun.current = false;
    callback();
  }
}
