import { spaces, radius, breakpoints, shadows, fontFamily, fontWeights, zIndexes, stackingOrder } from "./designTokens";
import { nessieColors } from "./nessieColors";

/**
 * LEGACY NESSIE COLORS: DEPRECATED
 */

export const nessieContentColorStyles = {
  dt_content_primary: nessieColors.dt_taro80,
  dt_content_secondary: nessieColors.dt_taro60,
  dt_content_tertiary: nessieColors.dt_taro50,
  dt_content_light: nessieColors.dt_taro0,
  dt_content_disabled: nessieColors.dt_taro40,
  dt_content_accent: nessieColors.dt_aqua60, // neutral
  dt_content_success: nessieColors.dt_kiwi60,
  dt_content_warning: nessieColors.dt_mango60,
  dt_content_danger: nessieColors.dt_watermelon60,
  dt_content_plus: nessieColors.dt_grape60,
};

export const nessieBackgroundColorStyles = {
  dt_background_primary: nessieColors.dt_taro0,
  dt_background_secondary: nessieColors.dt_taro10,
  dt_background_tertiary: nessieColors.dt_taro20,
  dt_background_disabled: nessieColors.dt_taro10,
  dt_background_accent: nessieColors.dt_aqua20, // neutral
  dt_background_success: nessieColors.dt_kiwi20,
  dt_background_warning: nessieColors.dt_mango20,
  dt_background_danger: nessieColors.dt_watermelon20,
};

export const nessieBorderColorStyles = {
  dt_border_functional: nessieColors.dt_taro50,
  dt_border_stylistic: nessieColors.dt_taro30,
  dt_border_divider: nessieColors.dt_taro20,
  dt_border_transparent: nessieColors.dt_taro50,
  dt_border_disabled: nessieColors.dt_taro20,
  dt_border_active: nessieColors.dt_aqua60,
};

export const nessieBrandColorStyles = {
  dt_brand_classDojo: nessieColors.dt_aqua60,
  dt_brand_plus: nessieColors.dt_grape60,
  dt_brand_tutor: nessieColors.dt_blueberry60,
};

export const contentColorStyleToToken: Record<ColorStyles, NessieContentColorKinds> = {
  primary: "dt_content_primary",
  secondary: "dt_content_secondary",
  tertiary: "dt_content_tertiary",
  light: "dt_content_light",
  disabled: "dt_content_disabled",
  accent: "dt_content_accent",
  success: "dt_content_success",
  warning: "dt_content_warning",
  danger: "dt_content_danger",
  plus: "dt_content_plus",
} as const;

export type ColorStyles =
  | "primary"
  | "secondary"
  | "tertiary"
  | "light"
  | "disabled"
  | "accent"
  | "success"
  | "warning"
  | "plus"
  | "danger";

export type NessieThemeBorders = keyof (typeof theme)["borders"];
export type NessieThemeColors = keyof (typeof theme)["colors"];
export type NessieContentColorKinds = keyof typeof nessieContentColorStyles;
export type NessieBackgroundColorKinds = keyof typeof nessieBackgroundColorStyles;
export type NessieBorderColorKinds = keyof typeof nessieBorderColorStyles;
export type NessieBrandColorKinds = keyof typeof nessieBrandColorStyles;
export type NessieTheme = typeof theme;

//
// Primary theme object required by our ThemeProvider
//
export const theme = {
  fontFamily,
  fontWeights,
  space: spaces,
  radii: radius,
  shadows,
  breakpoints,
  zIndexes,
  stackingOrder,
  colors: {
    ...nessieColors,
    ...nessieContentColorStyles,
    ...nessieBackgroundColorStyles,
    ...nessieBorderColorStyles,
    ...nessieBrandColorStyles,
  },
  borders: {
    none: "none",
    unset: "unset",
    dt_card: `2px solid ${nessieBorderColorStyles.dt_border_stylistic}`,
    dt_functional: `2px solid ${nessieBorderColorStyles.dt_border_functional}`,
    dt_active: `2px solid ${nessieBorderColorStyles.dt_border_active}`,
    dt_divider: `2px solid ${nessieBorderColorStyles.dt_border_divider}`,
    dt_disabled: `2px solid ${nessieBorderColorStyles.dt_border_disabled}`,
  },
};
