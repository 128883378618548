import { toDate as biggerToDate } from "date-fns-tz";

/** web-monorepo/dates timezone context:
 * Date objects in JS are always in the users browser timezone. Marketplace
 * allows users to set a timezone, which is used to display (and calculate)
 * dates. This module provides helper functions for calculations, but some
 * shouldn't be exported and used extenrally because it's confusing. For example,
 * if a user has their browser set to GMT-7, and their local display zone set to
 * GMT+3, and they want to do a calculation in UTC, we need to shift the date so
 * it "seems like" it's in the local timezone for the calculation, and then
 * unshift on the way out. The date object is _always actually in_ the browser
 * local time, but if we need to do, say, an endOfDay calculation in UTC,
 * or the users display timezone, we need to shift it around before and after
 * the calculation.
 */

/**
 * Parses a standard string to a Date object in a particular time zone. This is
 * the key to multi-timezone support. It is trivial to implement in UTC or
 * browser-local timezone, that implementation is here. If you import & call
 * enableGlobalTimezoneSupport(), it replaces the implementation with the "real"
 * version from date-fns-tz. This comes with a nontrivial size penalty, so it's
 * opt-in.
 */

const ISO_STRING_WITHOUT_TIMEZONE_RE =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?$/;

function checkTimezone(timeZone: string | null | undefined) {
  if (timeZone && timeZone !== null && timeZone !== "UTC") {
    throw new Error(
      "timeZone must be UTC, null or undefined. Call enableGlobalTimezoneSupport() to allow other timezones."
    );
  }
}

export function toDateInLocalOrUTC(
  input: string,
  options?: { timeZone?: string }
) {
  if (!ISO_STRING_WITHOUT_TIMEZONE_RE.test(input)) {
    throw new Error("Invalid input - must supply ISO string without time zone");
  }

  checkTimezone(options?.timeZone);

  return new Date(options?.timeZone === "UTC" ? `${input}Z` : input);
}

export let toDate = toDateInLocalOrUTC;

/**
 * **enableGlobalTimezoneSupport**: This call is required to enable global
 * timezones. Importing it costs ~50kb gzipped, calling it is free and instant.
 * It is essentially a tree shaking hack to prevent requiring date-time-tz
 * if you don't need to support time zones other than UTC and the browser-local
 * one. You can always _render_ in any time zone you want, but doing
 * calculations and comparisons in other time zones requires quite a bit of
 * parsing trickery to get exactly right.
 */

export function enableGlobalTimezoneSupport() {
  toDate = biggerToDate;
}

export type TZ = string;

let localTimezoneOverride: null | string = null;

export function getLocalTimezoneOverride() {
  return localTimezoneOverride;
}

export function overrideLocalTimezone(tz: string | null) {
  if (toDate !== biggerToDate) checkTimezone(tz);
  localTimezoneOverride = tz;
}
