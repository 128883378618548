import * as React from "react";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import { RAW_cssValue } from "../../nessie/stylingLib";
import { nessiePropCheckerWrapper } from "./nessiePropCheckerWrapper";
import {
  colorNessieResponsivePropType,
  NessieFunctionComponent,
  NessieComponentProps,
  iconNessiePropTypes,
  NessieIconSizes,
} from "./propTypes";
import { NessieThemeColors } from "../../nessie/components/theme";
import { NessieSpaceSizes } from "../../nessie/components/designTokens";

type RoundedIconProps = NessieComponentProps & {
  icon: React.ReactElement;
  size?: NessieIconSizes;
  color?: NessieThemeColors;
  backgroundColor?: NessieThemeColors;
  cursor?: string;
  disabled?: boolean;
  "aria-label"?: string;
} & (
    | {
        onClick?: undefined;
        /**
         * The name will get used for automated product events.
         * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
         */
        "data-name"?: undefined;
      }
    | {
        onClick: React.MouseEventHandler;
        /**
         * The name will get used for automated product events.
         * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
         */
        "data-name": string;
      }
  );

const paddings: { [index: string]: NessieSpaceSizes } = { l: "s", m: "s", s: "xs", xs: RAW_cssValue("5px") };

export let RoundedIcon: NessieFunctionComponent<RoundedIconProps> = ({
  icon,
  size = "m",
  backgroundColor,
  cursor,
  onClick,
  ["data-name"]: dataName,
  disabled,
  ["aria-label"]: ariaLabel,
}: RoundedIconProps) => {
  if (!dataName || !onClick) {
    return (
      <div
        sx={{
          display: "inline-flex",
          alignItems: "center",
          backgroundColor,
          borderRadius: "dt_radius_round",
          padding: paddings[size] || "s",
          cursor,
          position: "relative",
        }}
      >
        {React.isValidElement(icon) && icon}
      </div>
    );
  }

  return (
    <UnstyledButton
      data-name={dataName}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      sx={{
        display: "inline-flex",
        alignItems: "center",
        backgroundColor,
        borderRadius: "dt_radius_round",
        padding: paddings[size] || "s",
        cursor,
        position: "relative",
      }}
    >
      {React.isValidElement(icon) && icon}
    </UnstyledButton>
  );
};

RoundedIcon.displayName = "RoundedIcon";

RoundedIcon.nessiePropTypes = {
  backgroundColor: colorNessieResponsivePropType,
  ...iconNessiePropTypes,
};

RoundedIcon = nessiePropCheckerWrapper(RoundedIcon);
