/* eslint-disable @web-monorepo/no-style-prop */

import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useEffect } from "react";

// Copied from https://github.com/remix-run/react-router/blob/13ac1b6c5cc2c3f99629bed6ed0ccc8542de0d94/packages/react-router/lib/hooks.tsx#L504-L543
// Modified:
// - to fit eslint rules
// - effect that lets error bubble up to window.onerror

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// WE SHOULD ABSOLUTELY REVISIT WITH SOME BETTER UX
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export function ReactRouterErrorBoundary() {
  const error = useRouteError();

  useEffect(() => {
    const errorEvent = new ErrorEvent("error", { error });
    window.dispatchEvent(errorEvent);
  }, [error]);

  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : JSON.stringify(error);
  const stack = error instanceof Error ? error.stack : null;
  const lightgrey = "rgba(200,200,200, 0.5)";
  const preStyles = { padding: "0.5rem", backgroundColor: lightgrey };
  const codeStyles = { padding: "2px 4px", backgroundColor: lightgrey };

  let devInfo = null;
  if (Config.nodeEnv !== "production") {
    // eslint-disable-next-line no-console
    console.error("Error handled by React Router default ErrorBoundary:", error);

    devInfo = (
      <>
        <p>💿 Hey developer 👋</p>
        <p>
          You can provide a way better UX than this when your app throws errors by providing your own{" "}
          <code style={codeStyles}>ErrorBoundary</code> or <code style={codeStyles}>errorElement</code> prop on your
          route.
        </p>
      </>
    );
  }

  return (
    <>
      <h2>Unexpected Application Error!</h2>
      <h3 style={{ fontStyle: "italic" }}>{message}</h3>
      {stack ? <pre style={preStyles}>{stack}</pre> : null}
      {devInfo}
    </>
  );
}
