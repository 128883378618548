import { useState, useCallback } from "react";

interface StatefulRef<T> {
  (instance: T | null): void;
  current: T | null;
}

/**
 * Use this hook to create a ref that will cause a re-render when the ref is updated like setState would.
 * This can be useful when you need to get a ref to an actual html element via the normal react
 * `ref={someRef}` means but need to have to tell you when the underlying element changes.
 *
 * Use sparingly.
 * @param initialVal The initial value of the ref
 * @returns An object that looks like a ref but will cause a re-render when the ref is updated like setState would.
 */
const useStatefulRef = <T>(initialVal?: T | null): StatefulRef<T> => {
  const [current, setCurrent] = useState<T | null>(initialVal ?? null);

  const callback = useCallback((value: T | null) => {
    setCurrent(value ?? null);
  }, []);

  return Object.assign(callback, { current });
};

export default useStatefulRef;
