import { CalendarDate } from "@internationalized/date";
import { useCalendarCell } from "@react-aria/calendar";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { CalendarState, RangeCalendarState } from "@react-stately/calendar";
import { useRef } from "react";
import { LabelText } from "../../../nessie";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";

export function CalendarCell(props: { state: CalendarState | RangeCalendarState; date: CalendarDate }) {
  const ref = useRef(null);
  const { cellProps, buttonProps, formattedDate, isSelected, isDisabled, isOutsideVisibleRange } = useCalendarCell(
    props,
    props.state,
    ref,
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td {...cellProps} aria-hidden={isOutsideVisibleRange || isDisabled}>
      <div
        ref={ref}
        hidden={isOutsideVisibleRange}
        {...mergeProps(buttonProps, focusProps)}
        tabIndex={isDisabled || isOutsideVisibleRange ? -1 : 0}
        sx={{ outline: "none" }}
      >
        <LabelText
          sx={{
            ...cellStyles,
            ...(isDisabled || isOutsideVisibleRange ? { pointerEvents: "none" } : {}),
            ...(isSelected ? selectedStyles : {}),
            ...(isFocusVisible ? focusStyles : {}),
          }}
          kind={isDisabled || isOutsideVisibleRange ? "disabled" : isSelected ? "light" : "primary"}
        >
          {formattedDate}
        </LabelText>
      </div>
    </td>
  );
}

const selectedStyles: ThemeUIStyleObject = {
  backgroundColor: "dt_content_accent",
  color: "dt_content_light",
};

const focusStyles: ThemeUIStyleObject = { border: "dt_card", borderColor: "dt_border_functional" };

const cellStyles: ThemeUIStyleObject = {
  borderRadius: "dt_radius_round",
  position: "relative",
  outline: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  height: "40px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "dt_content_tertiary",
    color: "dt_content_light",
  },
};
