import * as React from "react";
import { ThemeProvider } from "../stylingLib";
import { theme } from "./theme";
import type { Theme } from "./useColorTheme";

export const NessieThemeProvider = ({
  children,
  selectedTheme,
}: {
  children?: React.ReactNode;
  selectedTheme?: Theme;
}) => {
  return <ThemeProvider theme={selectedTheme || theme}>{children}</ThemeProvider>;
};
