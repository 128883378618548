import * as React from "react";
import type { ThemeUIStyleObject } from "@theme-ui/core/dist/declarations/src";
import { LabelText } from "../../nessie";
import { ColorStyles } from "../../nessie/components/theme";

export type LabelFormElementProps = {
  labelText: string | React.ReactElement;
  kind?: ColorStyles;
  labelSX?: ThemeUIStyleObject;
} & LabelTypes &
  optionalTypes;

type LabelTypes = React.LabelHTMLAttributes<HTMLLabelElement>;
type optionalTypes =
  | {
      htmlFor: string;
      children?: React.ReactNode;
    }
  | {
      htmlFor?: never;
      children: React.ReactNode;
    };

/**
 * Component for laying out an input together with a label It is already used in Nessie components like TextField and TextArea,
 * only use it to create other nessie components.
 */
const LabelFormElement = ({
  htmlFor,
  labelText,
  children,
  kind,
  labelSX,
  ...otherProps
}: LabelFormElementProps): JSX.Element => {
  return (
    <label htmlFor={htmlFor} sx={{ width: children ? "100%" : "fit-content" }} {...otherProps}>
      <LabelText kind={kind} sx={{ padding: "xs", textWrap: "balance", ...(labelSX ?? {}) }}>
        {labelText}
      </LabelText>
      {children}
    </label>
  );
};

export default LabelFormElement;
