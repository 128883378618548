import { type Mode, type TimeZone, startOf, endOf } from "./periods";

export function isAfter(
  date: Date,
  dateToCompare: Date,
  options?: { mode?: Mode; inclusive: boolean; tz?: TimeZone }
): boolean {
  const mode = options?.mode;
  const tz = options?.tz;
  const inclusive = options?.inclusive ?? false;
  const dateStart = mode ? startOf(date, mode, tz) : date;
  const dateToCompareStart = mode
    ? startOf(dateToCompare, mode, tz)
    : dateToCompare;

  if (dateStart.getTime() === dateToCompareStart.getTime()) {
    return inclusive;
  }

  return dateStart.getTime() > dateToCompareStart.getTime();
}

export function isBefore(
  date: Date,
  dateToCompare: Date,
  options?: { mode?: Mode; inclusive?: boolean; tz?: TimeZone }
): boolean {
  const mode = options?.mode;
  const tz = options?.tz;
  const inclusive = options?.inclusive ?? false;
  const dateEnd = mode ? endOf(date, mode, tz) : date;
  const dateToCompareEnd = mode
    ? endOf(dateToCompare, mode, tz)
    : dateToCompare;

  if (dateEnd.getTime() === dateToCompareEnd.getTime()) {
    return inclusive;
  }

  return dateEnd.getTime() < dateToCompareEnd.getTime();
}

export function isSame(
  date: Date,
  dateToCompare: Date,
  options?: { mode: Mode; tz?: TimeZone }
): boolean {
  const mode = options?.mode;
  const tz = options?.tz;
  const dateStart = mode ? endOf(date, mode, tz) : date;
  const dateToCompareStart = mode
    ? endOf(dateToCompare, mode, tz)
    : dateToCompare;

  return dateStart.getTime() === dateToCompareStart.getTime();
}

export function isBetween(
  date: Date,
  start: Date,
  end: Date,
  options?: {
    mode?: Mode;
    inclusivity?: "[]" | "()";
    tz?: TimeZone;
  }
): boolean {
  const inclusivity = options?.inclusivity || "()";
  const tz = options?.tz;
  const mode = options?.mode;

  return (
    isAfter(date, start, { mode, tz, inclusive: inclusivity[0] === "[" }) &&
    isBefore(date, end, { mode, tz, inclusive: inclusivity[1] === "]" })
  );
}
