import { PropsWithChildren } from "react";
import { nessiePropCheckerWrapper } from "./nessiePropCheckerWrapper";
import {
  NessieComponentProps,
  oneOfNessieResponsivePropType,
  spacingNessieResponsivePropType,
  NessieFunctionComponent,
} from "./propTypes";
import { NessieSpaceSizes } from "./designTokens";

type SpaceProps = NessieComponentProps & {
  kind?: "inline" | "block";
  size: NessieSpaceSizes;
};

export let Space: NessieFunctionComponent<PropsWithChildren<SpaceProps>> = ({
  children,
  kind = "block",
  size,
}: PropsWithChildren<SpaceProps>) => {
  const props: { marginRight?: number | string; marginBottom?: number | string } = {};
  if (kind === "inline") {
    props.marginRight = size;
  }
  if (kind === "block") {
    props.marginBottom = size;
  }
  return (
    <div sx={kind === "inline" ? { marginRight: size } : kind === "block" ? { marginBottom: size } : undefined}>
      {children}
    </div>
  );
};

Space.displayName = "Space";

Space.nessiePropTypes = {
  kind: oneOfNessieResponsivePropType(["inline", "block"]),
  size: spacingNessieResponsivePropType,
};

Space = nessiePropCheckerWrapper(Space);
