import { ThemeUIStyleObject } from "../../stylingLib";
import { BaseText, BaseTextProps } from "./baseText";

// eslint-disable-next-line react-refresh/only-export-components
export const displayTextLevels = [1, 2, 3, 4] as const;
export type DisplayTextLevelsType = (typeof displayTextLevels)[number];

export type DisplayTextProps = {
  level?: DisplayTextLevelsType;
} & BaseTextProps;

export function DisplayText({ level = 1, ...props }: DisplayTextProps) {
  return <BaseText sx={displayTextStyles[level]} {...props} />;
}

export default DisplayText;

// eslint-disable-next-line react-refresh/only-export-components
export const displayTextStyles: Record<DisplayTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "69px",
    lineHeight: "72px",
    letterSpacing: "-0.9px",
    fontWeight: "dt_extraBold",
  },
  2: {
    fontSize: "55px",
    lineHeight: "60px",
    letterSpacing: "-0.6px",
    fontWeight: "dt_extraBold",
  },
  3: {
    fontSize: "44px",
    lineHeight: "48px",
    letterSpacing: "-0.3px",
    fontWeight: "dt_extraBold",
  },
  4: {
    fontSize: "35px",
    lineHeight: "42px",
    letterSpacing: "-0.3px",
    fontWeight: "dt_extraBold",
  },
};
