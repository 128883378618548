import "intersection-observer";
import { useEffect, useState, RefObject } from "react";

const useIntersectionObserver = (
  ref: RefObject<Element>,
  { root = null, rootMargin = "0px", threshold = 0 }: IntersectionObserverInit = {},
): boolean | undefined => {
  const [isIntersecting, setIsIntersecting] = useState<boolean | undefined>();

  const { current: node } = ref;

  useEffect(() => {
    if (!node) return;

    const observer = new IntersectionObserver(
      (
        [entry], // bulk ignoring existing errors
      ) =>
        // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
        setIsIntersecting(entry.isIntersecting),
      {
        root,
        rootMargin,
        threshold,
      },
    );

    observer.observe(node);

    return () => observer.disconnect();
  }, [node, root, rootMargin, threshold]);

  return isIntersecting;
};

export default useIntersectionObserver;
