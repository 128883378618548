import isPlainObject from "lodash/isPlainObject";
import { theme, NessieThemeColors } from "./theme";

export type NessieComponentProps = {
  _dangerouslyAllowResponsiveProps?: boolean;
  _dangerouslyAllowCustomSpacing?: boolean;
  _dangerouslyAllowCustomColors?: boolean;
  _dangerouslyDisableNessiePropTypes?: boolean;
};

export type NessiePropTypeError = Error & {
  title: string;
  description: string;
};

type NessiePropTypeValidatorSingleProp<P extends NessieComponentProps> = (
  props: P,
  prop: keyof P,
  displayName?: string,
) => NessiePropTypeError | undefined;

type NessiePropTypeValidatorOneOf<P extends NessieComponentProps> = (
  types: unknown[],
) => NessiePropTypeValidatorSingleProp<P>;

type NessiePropTypes<P extends NessieComponentProps> =
  | NessiePropTypeValidatorSingleProp<P>
  | NessiePropTypeValidatorOneOf<P>;

/**
 * Allows typing a React Function Component that also has the additional custom
 * `nessiePropTypes` property to specify Nessie property validators
 */
export type NessieFunctionComponent<P extends NessieComponentProps> = React.FunctionComponent<P> & {
  nessiePropTypes?: Partial<Record<keyof P, NessiePropTypes<P>>>;
};

export function isNessiePropTypeValidatorSingleProp<T extends NessieComponentProps & { [key: string]: unknown }>(
  value: unknown,
): value is NessiePropTypeValidatorSingleProp<T> {
  return !!value && typeof value === "function";
}

// -----------------
// Responsive nessie prop type
// For now responsive props  are experimental, we want to warn the users about it.
//
function responsiveNessiePropType<P extends NessieComponentProps & { [key: string]: unknown }>(
  props: P,
  prop: keyof P,
  displayName: string,
) {
  const propValue = props[prop];
  if (propValue === undefined) return;

  if (isPlainObject(propValue) && !props._dangerouslyAllowResponsiveProps) {
    const error = new Error(
      "Experimental responsive prop used. Please add the prop _dangerouslyAllowResponsiveProps is you wish to use  the experimental responsive API. You can read more about it here https://components.classdojo.com/nessie/#/#responsive",
    );

    return Object.assign(error, {
      title: "Experimental responsive prop used",
      description: `
        Responsive props are still experimental, if you want to use them please add the prop <strong>_dangerouslyAllowResponsiveProps</strong>
        so we can easily spot the emerging patterns and reassess in upcoming releases whether we stick to this API or a different one.
        </br>
        Component <strong>'${displayName}'</strong>
        </br>
        You can read  more here ${getStringLink("https://components.classdojo.com/nessie/#/#responsive")}
      `,
    });
  }
}

export function oneOfNessieResponsivePropType<P extends NessieComponentProps & { [key: string]: unknown }>(
  types: unknown[],
) {
  return (props: P, prop: string & keyof P, displayName: string) => {
    const propValue = props[prop];
    if (propValue === undefined) return;

    // If is a responsive prop type then lets validate by the responsive validator and skip the validation here
    // TODO: Would be nice to validate the individual responsive values but for now responsive api is experimental,
    // we will revisit later.
    if (isPlainObject(propValue)) {
      return responsiveNessiePropType(props, prop, displayName);
    }

    if (!types.includes(propValue)) {
      const error = new Error(`One of these values was expected '[${types.join(", ")}] but instead got '${propValue}'`);

      return Object.assign(error, {
        title: "Wrong prop value provided. Check possible types",
        description: `
        Prop <strong>${prop}</strong> can only take one of the following values <strong>[${types.join(", ")}]</strong>.
        Instead we got <strong>'${propValue}'</strong>
        </br>
        Component <strong>'${displayName}'</strong>
      `,
      });
    }
  };
}

// ------------------
// Spacing Prop Type
//
export function spacingNessieResponsivePropType<P extends NessieComponentProps & { [key: string]: unknown }>(
  props: P,
  prop: string & keyof P,
  displayName: string,
) {
  const propValue = props[prop];
  if (propValue === undefined) return;

  // If is a responsive prop type then lets validate by the responsive validator and skip the validation here
  // TODO: Would be nice to validate the individual responsive values but for now responsive api is experimental,
  // we will revisit later.
  if (isPlainObject(propValue)) {
    return responsiveNessiePropType(props, prop, displayName);
  }

  // const isSpacingProp = SPACING_PROPS.includes(prop);
  // if (!isSpacingProp) return;

  if (props._dangerouslyAllowCustomSpacing) return;

  const isNotASpaceFromTheme = !ALLOWED_SPACE_VALUES.includes(`${propValue}`);
  if (isNotASpaceFromTheme) {
    const error = new Error(`Spacing prop error. Please use one of the space values from our theme`);

    return Object.assign(error, {
      title: "Spacing prop error",
      description: `
        You are trying to hardcode a space unit with pixels or other non supported values.
        We encourage using a value of spacing from our theme.
        Check more here ${getStringLink("https://components.classdojo.com/nessie/#/spacing")}
        </br></br>
        The error was found on the prop <strong>'${prop}'</strong>, where you used the value <strong>'${propValue}'</strong>.
        On the component <strong>'${displayName}'</strong>
        </br>
        Allowed space values: <strong>[${ALLOWED_SPACE_VALUES.join(" ,")}]</strong>
        </br>
        If this is a case that you consider is not well supported by nessie-web then you can opt-in to use hardcoded values.
        To do so add the prop <strong>_dangerouslyAllowCustomSpacing</strong> to the component.
      `,
    });
  }
}

// ---------
// Color Prop Type
//
export function colorNessieResponsivePropType<P extends NessieComponentProps & { [key: string]: unknown }>(
  props: P,
  prop: keyof P & string,
  displayName: string,
) {
  const propValue = props[prop];
  if (propValue === undefined) return;

  // If is a responsive prop type then lets validate by the responsive validator and skip the validation here
  // TODO: Would be nice to validate the individual responsive values but for now responsive api is experimental,
  // we will revisit later.
  if (isPlainObject(propValue)) {
    return responsiveNessiePropType(props, prop, displayName);
  }

  const isColorProp = COLOR_PROPS.includes(prop);
  const themeColors = Object.keys(theme.colors);

  if (!isColorProp) return;
  if (props._dangerouslyAllowCustomColors) return;
  const isNotFromTheme = typeof propValue === "string" && !themeColors.includes(propValue);

  if (isNotFromTheme) {
    const error = new Error("Color prop error. Please use a color from our theme");

    return Object.assign(error, {
      title: "Color prop error",
      description: `
        You are trying to set a hardcoded color rather than using one from our palette.
        We encourage you use a value from our theme.
        The error was found on the prop <strong>'${prop}'</strong>, where you used the value
        <strong>'${propValue}'</strong>. We highlighted on red the responsible component <strong>'${displayName}'</strong>
        </br></br>
        These are the supported values: ${getStringThemeColors()}
        </br>
        Check more here ${getStringLink("https://components.classdojo.com/nessie/#/palette")}
        </br>
        If this is a case that you consider is not well supported by nessie-web then you can opt-in
        to use hardcoded values.
        To do so add the prop <strong>_dangerouslyAllowCustomColors</strong> to the component.
      `,
    });
  }
}

export function stringNessieResponsivePropType<P extends NessieComponentProps & { [key: string]: unknown }>(
  props: P,
  prop: string & keyof P,
  displayName: string,
) {
  const propValue = props[prop];

  // If is a responsive prop type then lets validate by the responsive validator and skip the validation here
  // TODO: Would be nice to validate the individual responsive values but for now responsive api is experimental,
  // we will revisit later.
  if (isPlainObject(propValue)) {
    return responsiveNessiePropType(props, prop, displayName);
  }

  if (typeof propValue !== "string") {
    const description = `Expected string on prop '${prop}', but got value '${propValue}' of type '${typeof propValue}'. On component <strong>'${displayName}'</strong>`;
    const error = new Error(description);
    return Object.assign(error, {
      title: `Prop of wrong type provided`,
      description,
    });
  }
}

// ----------------------------
// Helpers
//
const getStringThemeColors = () => {
  const colors = Object.keys(theme.colors).map(
    (color: NessieThemeColors) => `
    <div style="display:flex;align-items:center;padding: 3px">
    <span style="display: inline-block;margin-right: 2px;border: 1px solid gray;border-radius: 50%;width: 20px; height: 20px; background-color: ${theme.colors[color]}"></span>
    <strong style="display: inline-block">${color}</strong></div>
    `,
  );

  return `<div style="display: flex;flex-wrap: wrap">${colors.join(" ")}</div>`;
};
const getStringLink = (link: string) => `<a style="color: black" href="${link}" target="blank">${link}</a>`;

const COLOR_PROPS = ["color", "borderColor", "backgroundColor"];

const ALLOWED_SPACE_VALUES = [
  "0",
  "xxs",
  "xs",
  "s",
  "m",
  "l",
  "xl",
  "xxl",
  "auto",
  "dt_xxs",
  "dt_xs",
  "dt_s",
  "dt_m",
  "dt_l",
  "dt_xl",
  "dt_xxl",
];

const SIZES = { xs: "12px", s: "18px", m: "24px", l: "36px", xl: "48px", xxl: "56px" };
export type NessieIconSizes = keyof typeof SIZES;

export const iconNessiePropTypes = {
  color: colorNessieResponsivePropType,
  size: oneOfNessieResponsivePropType(Object.keys(SIZES)),
};
