import { TimeZone } from "./periods";
import { getLocalTimezoneOverride } from "./timezone";

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function dayParts(date: Date, timeZone?: TimeZone | null) {
  timeZone ??= getLocalTimezoneOverride() || undefined;
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    fractionalSecondDigits: 3,
    weekday: "short",
    hour12: false,
  });

  const parts = formatter.formatToParts(date);

  return {
    year: parts.find((part) => part.type === "year")!.value,
    month: parts.find((part) => part.type === "month")!.value,
    day: parts.find((part) => part.type === "day")!.value,
    hours: parts.find((part) => part.type === "hour")!.value,
    minutes: parts.find((part) => part.type === "minute")!.value,
    seconds: parts.find((part) => part.type === "second")!.value,
    milliseconds:
      parts.find((part) => part.type === "fractionalSecond")?.value ||
      date.getMilliseconds().toFixed(0).padStart(3, "0"),
    weekday: weekdays.indexOf(
      parts.find((part) => part.type === "weekday")!.value
    ),
  };
}
