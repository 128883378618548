// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CloseSmallIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CloseSmallIcon: NessieFunctionComponent<CloseSmallIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CloseSmallIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CloseSmallIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.43332 6.43332C5.85556 7.01108 5.85556 7.94782 6.43332 8.52558L9.90774 12L6.43332 15.4744C5.85556 16.0522 5.85556 16.9889 6.43332 17.5667C7.01108 18.1444 7.94782 18.1444 8.52558 17.5667L12 14.0923L15.4744 17.5667C16.0522 18.1444 16.9889 18.1444 17.5667 17.5667C18.1444 16.9889 18.1444 16.0522 17.5667 15.4744L14.0923 12L17.5667 8.52558C18.1444 7.94782 18.1444 7.01108 17.5667 6.43332C16.9889 5.85556 16.0522 5.85556 15.4744 6.43332L12 9.90774L8.52558 6.43332C7.94782 5.85556 7.01108 5.85556 6.43332 6.43332Z"
          />
          <mask
            id="CloseSmallIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={6}
            y={6}
            width={12}
            height={12}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.43332 6.43332C5.85556 7.01108 5.85556 7.94782 6.43332 8.52558L9.90774 12L6.43332 15.4744C5.85556 16.0522 5.85556 16.9889 6.43332 17.5667C7.01108 18.1444 7.94782 18.1444 8.52558 17.5667L12 14.0923L15.4744 17.5667C16.0522 18.1444 16.9889 18.1444 17.5667 17.5667C18.1444 16.9889 18.1444 16.0522 17.5667 15.4744L14.0923 12L17.5667 8.52558C18.1444 7.94782 18.1444 7.01108 17.5667 6.43332C16.9889 5.85556 16.0522 5.85556 15.4744 6.43332L12 9.90774L8.52558 6.43332C7.94782 5.85556 7.01108 5.85556 6.43332 6.43332Z"
            />
          </mask>
          <g mask="url(#CloseSmallIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CloseSmallIcon.nessiePropTypes = iconNessiePropTypes;
CloseSmallIcon = nessiePropCheckerWrapper(CloseSmallIcon);
export default CloseSmallIcon;
