// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronDownIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronDownIcon: NessieFunctionComponent<ChevronDownIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronDownIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronDownIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M11.9844 17.8C12.5014 17.804 13.0197 17.6087 13.4142 17.2143L20.3439 10.2846C21.1249 9.50357 21.1249 8.23724 20.3439 7.4562C19.5628 6.67515 18.2965 6.67515 17.5154 7.4562L11.9846 12.987L6.45376 7.45618C5.67271 6.67514 4.40638 6.67514 3.62534 7.45618C2.84429 8.23723 2.84429 9.50357 3.62534 10.2846L10.555 17.2143C10.9494 17.6087 11.4675 17.8039 11.9844 17.8Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronDownIcon.nessiePropTypes = iconNessiePropTypes;
ChevronDownIcon = nessiePropCheckerWrapper(ChevronDownIcon);
export default ChevronDownIcon;
