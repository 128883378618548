// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ArrowLeftIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ArrowLeftIcon: NessieFunctionComponent<ArrowLeftIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ArrowLeftIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ArrowLeftIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M2.15619 11.2238C2.0556 11.4625 2 11.7248 2 12C2 12.0053 2.00002 12.0106 2.00006 12.0159C1.99622 12.5327 2.19146 13.0507 2.58579 13.445L9.51544 20.3747C10.2965 21.1557 11.5628 21.1557 12.3439 20.3747C13.1249 19.5936 13.1249 18.3273 12.3439 17.5463L8.79763 14L20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10L8.82844 10L12.3439 6.48459C13.1249 5.70354 13.1249 4.43721 12.3439 3.65616C11.5628 2.87511 10.2965 2.87511 9.51544 3.65616L2.58965 10.582C2.5773 10.5942 2.5651 10.6067 2.55307 10.6193C2.3803 10.8002 2.24801 11.0055 2.15619 11.2238Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ArrowLeftIcon.nessiePropTypes = iconNessiePropTypes;
ArrowLeftIcon = nessiePropCheckerWrapper(ArrowLeftIcon);
export default ArrowLeftIcon;
