// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type StarIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let StarIcon: NessieFunctionComponent<StarIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "StarIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: StarIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M11.5099 2.5428L9.23618 8.68691H2.69927C1.78979 8.68691 1.84738 9.60859 2.41542 9.9158L7.81512 13.6023C7.24669 15.5479 6.05299 19.7464 5.82562 20.9752C5.59824 22.204 6.2993 21.8968 6.67826 21.5896L12.3625 17.2887C13.4994 18.2104 15.6721 20.6021 17.1949 21.5896C18.616 22.5112 18.5212 21.692 18.3317 20.9752L16.6257 13.6023C17.8573 12.5783 20.6054 10.5302 21.4574 9.9158C22.4608 9.19209 21.8363 8.89174 21.4574 8.68691H14.352C13.9731 7.04848 13.1015 3.52586 12.6467 2.5428C12.192 1.55974 11.6994 2.13319 11.5099 2.5428Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
StarIcon.nessiePropTypes = iconNessiePropTypes;
StarIcon = nessiePropCheckerWrapper(StarIcon);
export default StarIcon;
