import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { AnimationWrapper } from "./AnimationWrapper";
import { CloseButton } from "./CloseButton";
import { NessieThemeColors } from "./theme";

type TooltipProps = {
  className?: string;
  isOpen: boolean;
  children: React.ReactNode;
  animate?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
  color?: NessieThemeColors;
  onDismiss?: () => void;
  maxWidth?: string;
  triangleStyle?: ThemeUIStyleObject;
};

export const Tooltip = ({
  isOpen,
  placement = "top",
  color = "dt_content_primary",
  onDismiss,
  animate = false,
  maxWidth,
  triangleStyle = {},
  children,
  className,
}: TooltipProps) => {
  const weight = color.slice(-2);

  const baseTriangleStyle: ThemeUIStyleObject = {
    content: '" "',
    position: "absolute",
    width: "15px",
    height: "15px",
    backgroundColor: color,
  };

  const placementStyles: Record<string, ThemeUIStyleObject> = {
    top: {
      bottom: "0",
      left: "50%",
      borderRadius: RAW_cssValue("0 0 5px"),
      transform: "translate(-50%, 50%) rotate(45deg) skew(7deg, 7deg)",
    },
    bottom: {
      top: "0",
      left: "50%",
      borderRadius: RAW_cssValue("5px 0 0"),
      transform: "translate(-50%, -50%) rotate(45deg)  skew(7deg, 7deg)",
    },
    left: {
      top: "50%",
      right: "0",
      borderRadius: RAW_cssValue("0 5px 0 0"),
      transform: "translate(50%, -50%) rotate(45deg)  skew(-7deg, -7deg) scale(.75)",
    },
    right: {
      top: "50%",
      left: "0",
      borderRadius: RAW_cssValue("0 0 0 5px"),
      transform: "translate(-50%, -50%) rotate(45deg)  skew(-7deg, -7deg) scale(.75)",
    },
  };

  return (
    <AnimationWrapper type="bounceAnimation" animate={animate} isVisible={isOpen}>
      <div
        className={className}
        role="tooltip"
        sx={{
          minWidth: "100px",
          position: "relative",
          backgroundColor: color,
          color: parseInt(weight) <= 40 ? "dt_content_secondary" : "dt_content_light",
          borderRadius: "dt_radius_s",
          padding: "dt_s",
          paddingRight: onDismiss ? RAW_cssValue(`${12 + 12 + 15}px`) : null,
          maxWidth: maxWidth || null,
          ":after": { ...baseTriangleStyle, ...placementStyles[placement], ...triangleStyle },
        }}
      >
        {onDismiss && <CloseButton data-name="tooltip:close_button" onClick={onDismiss} />}
        <div>{children}</div>
      </div>
    </AnimationWrapper>
  );
};
