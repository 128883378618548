/* eslint-disable react-refresh/only-export-components */
import omit from "lodash/omit";
import { Trans } from "react-i18next";
import { TranslatableProps } from "../../propShapes/translatable";
import { translate } from "../../utils/translate";

export type TProps = Omit<JSX.IntrinsicElements["span"], "children" | "dangerouslySetInnerHTML"> & TranslatableProps;

/**
 * Translation component. Refer to our translation function to check how it can
 * be configured.
 *
 * ### [How does translation work?](https://www.notion.so/classdojo/How-Does-Translation-Work-b9ddf058dd6f43ebb5c4c1c4044d3124#9c9e05b364364615acd1074d94e3af42)
 */
const T = (props: TProps): JSX.Element => {
  if (props.components) {
    return <Trans i18nKey={props.str} defaults={props.fallback} values={props.subs} components={props.components} />;
  }

  const translation = translate(props);
  const spanProps = omit(props, ["htmlStr", "str", "subs", "fallback"]);

  if (props.htmlStr) {
    return <span {...spanProps} dangerouslySetInnerHTML={{ __html: translation }} />;
  } else {
    return <span {...spanProps}>{translation}</span>;
  }
};

let resolvedT = T;

export default function (props: TProps): JSX.Element {
  return resolvedT(props);
}

// eslint-disable-next-line react-refresh/only-export-components
export function overrideT(t: typeof T) {
  resolvedT = t;
}
