import {
  parseISO,
  parseJSON as dateFnsParseJSON,
  isValid,
  parse as dateFnsParse,
} from "date-fns";

import { captureException, addBreadcrumb } from "@web-monorepo/telemetry";

import { type TimeZone } from "./periods";
import { toDate, getLocalTimezoneOverride } from "./timezone";
import { utcToZonedTime } from "date-fns-tz";

/** Moment-migration date string parsing function. Reports differences between moment and date-fns, and currently returns the version moment gave. */
export function parse(
  input: undefined | number | string | Date = new Date()
): Date {
  let dateFnsVersion: Date | null = null;

  try {
    if (typeof input === "number") {
      dateFnsVersion = new Date(input);
    } else if (typeof input === "string") {
      dateFnsVersion = parseISO(input);
    } else if (input instanceof Date) {
      dateFnsVersion = new Date(input.getTime());
    } else if (input === undefined) {
      dateFnsVersion = new Date();
    } else {
      dateFnsVersion = new Date(NaN);
    }
  } catch (x) {
    captureException(x, { extra: { input } });
  }

  if (!isValid(dateFnsVersion)) {
    captureException(new Error(`Invalid date parse`), { extra: { input } });
  }

  if (!dateFnsVersion) {
    addBreadcrumb({ message: `parse returning NaN for input ${input}` });
    return new Date(NaN);
  }
  return dateFnsVersion;
}

/** Moment-migration date string parsing function. Reports differences between moment.utc() and date-fns's parseJSON, and currently returns the version moment gave. */
export function parseJSON(text: string): Date {
  let dateFnsVersion: Date | null = null;

  try {
    dateFnsVersion = dateFnsParseJSON(text);
  } catch (x) {
    captureException(x, { extra: { text } });
  }

  if (!isValid(dateFnsVersion)) {
    captureException(new Error(`Invalid date parseJSON`), {
      extra: { input: text },
    });
  }

  if (!dateFnsVersion) {
    addBreadcrumb({ message: `parseJSON returning NaN for input ${text}` });
    return new Date(NaN);
  }
  return dateFnsVersion;
}

function pad(n: number, length = 2): string {
  const str = n.toString();
  return str.padStart(length, "0");
}

/** **parseWithPattern** - note! this uses date-fns under the hood, so while the format strings look similar to Moment, they are not.
 * This function requires a _lot_ of machinery under the hood, do not import it unless you absolutely need it.
 */
export function parseWithPattern(
  text: string,
  pattern: string | string[],
  referenceDate?: Date,
  tz?: TimeZone
): Date {
  if (pattern instanceof Array) {
    for (const p of pattern) {
      const parsed = parseWithPattern(text, p, referenceDate || new Date(), tz);
      if (isValid(parsed)) {
        return parsed;
      }
    }
    return new Date(NaN);
  } else {
    const unadjusted = dateFnsParse(
      text,
      pattern,
      // ensure that we use the date out of the referenceDate _in our desired timezone_, not the device-local one.
      tz && referenceDate
        ? utcToZonedTime(referenceDate, tz)
        : referenceDate || new Date()
    );
    if (!isValid(unadjusted)) return unadjusted;
    const ret = toDate(
      `${unadjusted.getFullYear()}-${pad(unadjusted.getMonth() + 1)}-${pad(
        unadjusted.getDate()
      )}T${pad(unadjusted.getHours())}:${pad(unadjusted.getMinutes())}:${pad(
        unadjusted.getSeconds()
      )}.${pad(unadjusted.getMilliseconds(), 3)}`,
      { timeZone: tz ?? (getLocalTimezoneOverride() || undefined) }
    );

    return ret;
  }
}
