import { UnstyledButton } from "../../index";
import { CloseIcon } from "../../nessie/icons";
import { translate } from "../../utils/translate";

type CloseButtonProps = {
  onClick: () => void;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};

export const CloseButton = ({ onClick, "data-name": dataName }: CloseButtonProps) => {
  return (
    <UnstyledButton
      data-name={dataName}
      sx={{
        position: "absolute",
        top: "50%",
        right: "dt_s",
        transform: " translateY(-50%)",
      }}
      aria-label={translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
      onClick={onClick}
    >
      <CloseIcon size="s" color="dt_content_light" sx={{ verticalAlign: "middle" }} />
    </UnstyledButton>
  );
};
