import { TextField, TextFieldProps } from "./TextField";

export type IconTextFieldProps = TextFieldProps & {
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
};

export function IconTextField({ leftIcon, rightIcon, ...props }: IconTextFieldProps) {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {leftIcon && <div sx={{ position: "absolute", left: "24px", zIndex: "5" }}>{leftIcon}</div>}
      <TextField {...props} sx={{ input: { paddingLeft: "dt_xxl" } }} />
      {rightIcon && <div sx={{ position: "absolute", right: "24px", zIndex: "6" }}>{rightIcon}</div>}
    </div>
  );
}
