// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type DownCarrotIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let DownCarrotIcon: NessieFunctionComponent<DownCarrotIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "DownCarrotIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: DownCarrotIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M11.3022 14.7023C11.4994 14.8995 11.7585 14.9971 12.017 14.9951C12.2755 14.9971 12.5346 14.8995 12.7318 14.7023L16.727 10.7071C17.1175 10.3166 17.1175 9.68342 16.727 9.29289C16.5281 9.09403 16.2663 8.99644 16.0057 9.0001H7.99995C7.74879 9.00365 7.4987 9.10124 7.30705 9.29289C6.91653 9.68342 6.91653 10.3166 7.30705 10.7071L11.3022 14.7023Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
DownCarrotIcon.nessiePropTypes = iconNessiePropTypes;
DownCarrotIcon = nessiePropCheckerWrapper(DownCarrotIcon);
export default DownCarrotIcon;
