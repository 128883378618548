// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ArrowRightIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ArrowRightIcon: NessieFunctionComponent<ArrowRightIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ArrowRightIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ArrowRightIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M21.8451 12.7731C21.9449 12.5352 22 12.2741 22 12C22 11.9947 22 11.9895 21.9999 11.9842C22.0038 11.4674 21.8086 10.9493 21.4142 10.555L14.4846 3.62534C13.7035 2.84429 12.4372 2.84429 11.6561 3.62534C10.8751 4.40639 10.8751 5.67271 11.6561 6.45376L15.2024 10H4C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14L15.1716 14L11.6561 17.5154C10.8751 18.2965 10.8751 19.5628 11.6561 20.3439C12.4372 21.1249 13.7035 21.1249 14.4846 20.3439L21.4109 13.4175C21.4216 13.4069 21.4322 13.3961 21.4426 13.3852C21.6183 13.2024 21.7525 12.9944 21.8451 12.7731Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ArrowRightIcon.nessiePropTypes = iconNessiePropTypes;
ArrowRightIcon = nessiePropCheckerWrapper(ArrowRightIcon);
export default ArrowRightIcon;
