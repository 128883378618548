import { ThemeUIStyleObject } from "../../stylingLib";
import { BaseText, BaseTextProps } from "./baseText";

export function MinutiaeText(props: BaseTextProps) {
  return <BaseText sx={props.href ? minutiaeInlineLinkStyles : minutiaeTextStyles} {...props} />;
}

export default MinutiaeText;

// eslint-disable-next-line react-refresh/only-export-components
export const minutiaeTextStyles: ThemeUIStyleObject = {
  fontSize: "12px",
  lineHeight: "15px",
};

const minutiaeInlineLinkStyles: ThemeUIStyleObject = {
  fontSize: "15px",
  lineHeight: "18px",
  fontWeight: "dt_bold",
  color: "dt_content_accent",
};
