import { dayParts } from "./dayParts";
import { TimeZone } from "./periods";
import { isValid } from "date-fns";

export function getMilliseconds(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).milliseconds);
}

export function getSeconds(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).seconds);
}

export function getMinutes(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).minutes);
}

/** **getHours** returns the hour of the day (0-23) in the specified time zone */
export function getHours(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).hours) % 24;
}

/** **getDayOfMonth** returns the day-of-month (1-31) (equivalent to moment's date() or date-fns's getDate() ) */
export function getDayOfMonth(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).day);
}

/** **getMonth** returns the 0-indexed month, i.e. 0 = january, 11 = december */
export function getMonth(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).month) - 1;
}

export function getYear(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return Number(dayParts(date, timeZone).year);
}

export function getDayOfWeek(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  return dayParts(date, timeZone).weekday;
}

export function getISOWeekday(date: Date, timeZone?: TimeZone): number {
  if (!isValid(date)) return NaN;
  const weekday = getDayOfWeek(date, timeZone);
  return weekday === 0 ? 7 : weekday;
}
