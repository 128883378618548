import { ThemeUIStyleObject } from "../../stylingLib";
import { BaseText, BaseTextProps } from "./baseText";

// eslint-disable-next-line react-refresh/only-export-components
export const bodyTextLevels = [1, 2, 3] as const;
export type BodyTextLevelsType = (typeof bodyTextLevels)[number];

export type BodyTextProps = {
  level?: BodyTextLevelsType;
} & BaseTextProps;

export function BodyText({ level = 1, ...props }: BodyTextProps) {
  return <BaseText sx={props.href ? bodyInlineLinkStyles[level] : bodyTextStyles[level]} {...props} />;
}

export default BodyText;

// eslint-disable-next-line react-refresh/only-export-components
export const bodyTextStyles: Record<BodyTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "18px",
    lineHeight: "22px",
  },
  2: {
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
  },
  3: {
    fontSize: "13.5px",
    lineHeight: "16px",
  },
};

const bodyInlineLinkStyles: Record<BodyTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "dt_bold",
    color: "dt_content_accent",
  },
  2: {
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    fontWeight: "dt_bold",
    color: "dt_content_accent",
  },
  3: {
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: "dt_bold",
    color: "dt_content_accent",
  },
};
