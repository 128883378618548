import { createCalendar, getLocalTimeZone } from "@internationalized/date";
import { useCalendar } from "@react-aria/calendar";
import { useCalendarState } from "@react-stately/calendar";
import CalendarContent, { getCalendarDate } from "./calendar/CalendarContent";

type DatePickerProps = {
  value?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  onChange?: (date: Date) => void;
};

/**
 * Date picker. Wrapper around @react-aria/calendar with our custom flavor
 * for Class Dojo.
 */
const Calendar = ({ value, onChange, minDate, maxDate, className, ...props }: DatePickerProps): JSX.Element => {
  const localeInfo = new Intl.DateTimeFormat().resolvedOptions();

  const state = useCalendarState({
    ...props,
    minValue: minDate ? getCalendarDate(minDate) : undefined,
    maxValue: maxDate ? getCalendarDate(maxDate) : undefined,
    value: value ? getCalendarDate(value) : undefined,
    onChange: (selectedDate) => {
      onChange?.(selectedDate.toDate(getLocalTimeZone()));
    },
    locale: localeInfo.locale,
    createCalendar,
  });
  const calendarProps = useCalendar(props, state);

  return <CalendarContent state={state} {...props} {...calendarProps} className={className} />;
};

export default Calendar;
