// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CloseIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CloseIcon: NessieFunctionComponent<CloseIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CloseIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CloseIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 9.17157L16.2428 4.92893C17.0238 4.14788 18.2901 4.14788 19.0712 4.92893C19.8522 5.70997 19.8522 6.97631 19.0712 7.75735L14.8285 12L19.0712 16.2426C19.8522 17.0237 19.8522 18.29 19.0712 19.0711C18.2901 19.8521 17.0238 19.8521 16.2428 19.0711L12.0001 14.8284L7.75748 19.0711C6.97643 19.8521 5.7101 19.8521 4.92905 19.0711C4.148 18.29 4.148 17.0237 4.92905 16.2426L9.17169 12L4.92905 7.75735C4.148 6.97631 4.148 5.70998 4.92905 4.92893C5.7101 4.14788 6.97643 4.14788 7.75748 4.92893L12.0001 9.17157Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CloseIcon.nessiePropTypes = iconNessiePropTypes;
CloseIcon = nessiePropCheckerWrapper(CloseIcon);
export default CloseIcon;
