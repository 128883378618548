// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PortraitIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PortraitIcon: NessieFunctionComponent<PortraitIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PortraitIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PortraitIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12 1.97168C9.01732 1.97168 6.79617 4.72519 7.42732 7.64034C7.82372 9.47121 8.14411 11.6405 9.55387 12.8625C9.73339 13.0181 9.75904 13.2929 9.75904 13.5336V14.3605C9.63519 15.119 8.01457 15.6536 6.40596 16.1842C5.60576 16.4482 4.80852 16.7112 4.2 17.0002C3.49052 17.3373 3 18.0625 3 18.9002C3 20.06 3.9402 21.0002 5.1 21.0002H18.9C20.0598 21.0002 21 20.06 21 18.9002C21 18.0625 20.5095 17.3373 19.8 17.0002C19.1786 16.705 18.3592 16.4384 17.5381 16.1712C15.9181 15.644 14.2919 15.1148 14.1687 14.3605V13.562C14.1687 13.3398 14.1739 13.0747 14.3438 12.9377C15.842 11.7304 16.1667 9.51563 16.5727 7.64034C17.2039 4.72519 14.9827 1.97168 12 1.97168Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PortraitIcon.nessiePropTypes = iconNessiePropTypes;
PortraitIcon = nessiePropCheckerWrapper(PortraitIcon);
export default PortraitIcon;
