// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type EyeClosedIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let EyeClosedIcon: NessieFunctionComponent<EyeClosedIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "EyeClosedIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: EyeClosedIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 22 18"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.70711 0.292889C3.31659 -0.0976295 2.68341 -0.0976295 2.29289 0.292889C1.90237 0.683407 1.90237 1.31659 2.29289 1.7071L18.2929 17.7071C18.6834 18.0976 19.3166 18.0976 19.7071 17.7071C20.0976 17.3166 20.0976 16.6834 19.7071 16.2929L17.7758 14.3616C19.661 13.0375 21.1425 11.176 22 9C20.27 4.61 16 1.5 11 1.5C9.21863 1.5 7.52991 1.89474 6.01572 2.6015L3.70711 0.292889ZM8.24309 4.82887L9.70578 6.29156C10.0974 6.1046 10.5362 6 11 6C12.66 6 14 7.33999 14 9C14 9.4638 13.8954 9.90263 13.7084 10.2942L15.1711 11.7569C15.6949 10.9664 16 10.0186 16 9C16 6.24 13.76 4 11 4C9.98137 4 9.03358 4.30511 8.24309 4.82887Z"
          />
          <path d="M0 9C0.742889 7.11485 1.95416 5.46574 3.49048 4.19757L6.37959 7.08668C6.13503 7.67614 6 8.3224 6 9C6 11.76 8.24001 14 11 14C11.6776 14 12.3239 13.865 12.9133 13.6204L15.0715 15.7786C13.8024 16.2452 12.4311 16.5 11 16.5C6 16.5 1.73 13.39 0 9Z" />
          <path d="M8 9C8 8.90554 8.00435 8.81211 8.01283 8.71992L11.2801 11.9872C11.1879 11.9956 11.0945 12 11 12C9.34 12 8 10.66 8 9Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
EyeClosedIcon.nessiePropTypes = iconNessiePropTypes;
EyeClosedIcon = nessiePropCheckerWrapper(EyeClosedIcon);
export default EyeClosedIcon;
