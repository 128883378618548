import { ProductEvent } from "@classdojo/log-client/lib/client/client";

export type LogEventFunction = (event: string | ProductEvent) => void;

let logEvent: LogEventFunction = function () {
  throw new Error("You must initialize the component library with a `logEvent` function to log events!");
};

function defaultLogEvent(event: string | ProductEvent): void {
  return logEvent(event);
}

export default defaultLogEvent;

export function setLogEventFunction(fn: LogEventFunction): void {
  logEvent = fn;
}
