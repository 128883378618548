import { ThemeUIStyleObject } from "../stylingLib";
import { NessieThemeColors } from "./theme";

type ProgressBarProps = {
  value: number;
  max?: number;
  height?: number;
  borderWidth?: number;
  progressBarColor?: NessieThemeColors;
  backgroundBarColor?: NessieThemeColors;
  ["aria-label"]?: string;
};

export const ProgressBar = ({
  value,
  max = 100,
  height = 6,
  borderWidth = 0,
  progressBarColor,
  backgroundBarColor,
  "aria-label": ariaLabel,
}: ProgressBarProps) => {
  const hasBorder = borderWidth > 0;
  const borderProps: ThemeUIStyleObject = hasBorder
    ? { borderWidth: `${borderWidth}px`, borderColor: "dt_border_stylistic", borderStyle: "solid" }
    : {};
  return (
    <div
      sx={{
        backgroundColor: backgroundBarColor
          ? backgroundBarColor
          : hasBorder
            ? "dt_background_primary"
            : "dt_background_success",
        borderRadius: "dt_radius_s",
        height,
        overflow: "hidden",
        position: "relative",
        ...borderProps,
      }}
      aria-label={ariaLabel}
    >
      <div
        sx={{
          backgroundColor: progressBarColor ? progressBarColor : "dt_content_success",
          borderRadius: "dt_radius_s",
          height,
          width: fillBar(max, value),
        }}
      />
    </div>
  );
};

function fillBar(max: number, value: number) {
  max = Number(max);
  value = Number(value);
  const filledBar = max === 100 ? `${value}%` : `${(value / max) * 100}%`;
  return filledBar;
}
