import { useEffect } from "react";

const useScrollDetector = (scrollHandler: (event: Event) => void) => {
  useEffect(() => {
    const handleScroll = (event: Event) => {
      scrollHandler(event);
    };

    // Attach scroll event listener to the window
    window.addEventListener("scroll", handleScroll, true);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, [scrollHandler]);
};

export default useScrollDetector;
