import { useState } from "react";
import { UnstyledButton } from "../..";
import { EyeClosedIcon, EyeIcon } from "../../nessie/icons";
import { TextField, TextFieldProps, TEXT_FIELD_HEIGHT } from "./TextField";

type PasswordTextFieldProps = Omit<TextFieldProps, "type">;

export function PasswordTextField(props: PasswordTextFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <div sx={{ position: "relative" }}>
      {/* this ts ignore is necessary because we only want to use the password type in the PasswordTextField.
      if someone tries to use type=password in the default text field, an error should be thrown */}
      {/* @ts-expect-error: we don't allow TextField to have the password type this is the only exception*/}
      <TextField type={showPassword ? "text" : "password"} {...props} rightIndent="xxl">
        <UnstyledButton
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          aria-label={showPassword ? "hide password" : "show password"}
          sx={{
            height: TEXT_FIELD_HEIGHT,
            maxHeight: TEXT_FIELD_HEIGHT,
            position: "absolute",
            top: 0,
            right: 0,
            paddingX: "l",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          type="button"
          data-name="password_text_field:show_password"
        >
          {showPassword ? <EyeClosedIcon size="s" /> : <EyeIcon size="s" />}
        </UnstyledButton>
      </TextField>
    </div>
  );
}
