export function sanitizeQueryString(pathOrRoute: string): string {
  if (!pathOrRoute.includes("?")) return pathOrRoute;

  const [rest, queryString] = pathOrRoute.split("?");

  // Redact query param values, which sometimes contain PII or auth secrets
  const sanitizedPairs = queryString.split("&").map((pairs) => {
    const [key] = pairs.split("=");
    return `${key}=*`;
  });

  return `${rest}?${sanitizedPairs.join("&")}`;
}
