import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useRef } from "react";

export type WatchOptions = {
  // Call the callback immediately, with oldValue as undefined.
  immediate?: boolean;
  // Use deep comparison to detect changes instead of referential equality:
  deep?: boolean;
};

const shallowEqual = (arr1: Array<unknown>, arr2: Array<unknown>): boolean =>
  arr1.length === arr2.length && arr1.every((element, index) => arr2[index] === element);

// Can be returned from a watch callback to prevent the watch from running again.
export const HALT_WATCH = Symbol("useWatch:halt");

/** useWatch: is a hook that allows you to watch for changes in a value and run a callback when it changes.
 * Preferred to useEffect per https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes.
 * @param dependencies - The value to watch for changes.
 * @param callback - The callback to run when the value changes.
 * @param options - Options to control the behavior of the hook.
 * @param options.immediate - Call the callback immediately, with oldValue as undefined. (defaults to true)
 * @param options.deep - Use deep comparison to detect changes instead of referential equality.
 */
export default function useWatch<T>(
  dependencies: T,
  // If you got here because your callback needs to return a Promise or a cleanup function, use useEffect instead.
  callback: (newValue: T, oldValue: T | undefined) => void | null | undefined | typeof HALT_WATCH,
  options?: WatchOptions,
) {
  const firstRun = useRef<boolean>(true);
  const oldValue = useRef<T | undefined>(undefined);
  const halted = useRef<boolean>(false);

  if (halted.current) {
    return;
  }

  let changed = false;
  if (options?.deep) {
    changed = !isEqual(oldValue.current, dependencies);
  } else if (Array.isArray(dependencies) && Array.isArray(oldValue.current)) {
    changed = !shallowEqual(oldValue.current, dependencies);
  } else {
    changed = oldValue.current !== dependencies;
  }

  if (changed || (firstRun.current && options?.immediate !== false)) {
    const value = callback(dependencies, oldValue.current);
    if (value === HALT_WATCH) {
      halted.current = true;
      return;
    }
  }

  if (changed || firstRun.current) {
    if (options?.deep) oldValue.current = cloneDeep(dependencies);
    else if (Array.isArray(dependencies)) oldValue.current = [...dependencies] as typeof dependencies;
    else oldValue.current = dependencies;

    firstRun.current = false;
  }
}
