// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type BookIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let BookIcon: NessieFunctionComponent<BookIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "BookIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: BookIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.3486 6.05322C21.1868 6.01275 18.4752 5.68899 14.4282 7.18639C17.3825 5.12241 20.7011 4.96053 20.7416 4.96053C20.9844 4.96053 21.1463 4.75818 21.1463 4.51535C21.1463 4.27253 20.9439 4.07018 20.7011 4.07018C20.4988 4.07018 16.4517 4.27253 13.1332 7.06498C15.2781 3.82736 19.8512 2.89654 19.8917 2.89654C20.1345 2.85607 20.2964 2.61325 20.2559 2.37043C20.2155 2.12761 19.9726 1.96573 19.7703 2.0062C19.5275 2.04667 14.1045 3.13937 12 7.3078C9.89555 3.13937 4.47253 2.04667 4.22971 2.0062C3.98689 1.96573 3.78454 2.12761 3.74407 2.37043C3.7036 2.61325 3.86548 2.85607 4.1083 2.89654C4.14877 2.89654 8.72191 3.82736 10.8668 7.06498C7.58874 4.27253 3.50124 4.07018 3.29889 4.07018C3.05607 4.07018 2.85372 4.27253 2.85372 4.51535C2.85372 4.75818 3.0156 4.96053 3.25842 5.001C3.29889 5.001 6.61746 5.16288 9.57178 7.22686C6.86028 6.21511 4.75582 6.05322 3.62266 6.05322C3.0156 6.05322 2.69184 6.09369 2.65137 6.09369C2.24667 6.13416 1.96337 6.4984 2.00384 6.94357L2.97513 18.1943C3.0156 18.599 3.33936 18.8823 3.7036 18.9228C8.51956 19.0846 11.5144 21.27 11.5144 21.3105C11.6358 21.4319 11.7976 21.4724 12 21.4724C12.1214 21.4724 12.2428 21.4319 12.3642 21.3915C12.4047 21.351 12.4452 21.351 12.4856 21.3105C12.5261 21.27 15.4804 19.1251 20.2964 18.9228C20.7011 18.9228 21.0249 18.599 21.0249 18.1943L21.9962 6.94357C22.0366 6.45793 21.7533 6.09369 21.3486 6.05322ZM3.54172 7.55062C4.87723 7.55062 7.62921 7.83392 11.1501 9.57414V19.2061C9.89555 18.5585 7.54827 17.5468 4.39159 17.304L3.54172 7.55062ZM12.7285 19.2061V9.57414C16.2898 7.79345 19.0823 7.55062 20.3773 7.55062L19.487 17.304C16.3303 17.5468 13.983 18.5181 12.7285 19.2061Z"
          />
          <mask
            id="BookIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.3486 6.05322C21.1868 6.01275 18.4752 5.68899 14.4282 7.18639C17.3825 5.12241 20.7011 4.96053 20.7416 4.96053C20.9844 4.96053 21.1463 4.75818 21.1463 4.51535C21.1463 4.27253 20.9439 4.07018 20.7011 4.07018C20.4988 4.07018 16.4517 4.27253 13.1332 7.06498C15.2781 3.82736 19.8512 2.89654 19.8917 2.89654C20.1345 2.85607 20.2964 2.61325 20.2559 2.37043C20.2155 2.12761 19.9726 1.96573 19.7703 2.0062C19.5275 2.04667 14.1045 3.13937 12 7.3078C9.89555 3.13937 4.47253 2.04667 4.22971 2.0062C3.98689 1.96573 3.78454 2.12761 3.74407 2.37043C3.7036 2.61325 3.86548 2.85607 4.1083 2.89654C4.14877 2.89654 8.72191 3.82736 10.8668 7.06498C7.58874 4.27253 3.50124 4.07018 3.29889 4.07018C3.05607 4.07018 2.85372 4.27253 2.85372 4.51535C2.85372 4.75818 3.0156 4.96053 3.25842 5.001C3.29889 5.001 6.61746 5.16288 9.57178 7.22686C6.86028 6.21511 4.75582 6.05322 3.62266 6.05322C3.0156 6.05322 2.69184 6.09369 2.65137 6.09369C2.24667 6.13416 1.96337 6.4984 2.00384 6.94357L2.97513 18.1943C3.0156 18.599 3.33936 18.8823 3.7036 18.9228C8.51956 19.0846 11.5144 21.27 11.5144 21.3105C11.6358 21.4319 11.7976 21.4724 12 21.4724C12.1214 21.4724 12.2428 21.4319 12.3642 21.3915C12.4047 21.351 12.4452 21.351 12.4856 21.3105C12.5261 21.27 15.4804 19.1251 20.2964 18.9228C20.7011 18.9228 21.0249 18.599 21.0249 18.1943L21.9962 6.94357C22.0366 6.45793 21.7533 6.09369 21.3486 6.05322ZM3.54172 7.55062C4.87723 7.55062 7.62921 7.83392 11.1501 9.57414V19.2061C9.89555 18.5585 7.54827 17.5468 4.39159 17.304L3.54172 7.55062ZM12.7285 19.2061V9.57414C16.2898 7.79345 19.0823 7.55062 20.3773 7.55062L19.487 17.304C16.3303 17.5468 13.983 18.5181 12.7285 19.2061Z"
            />
          </mask>
          <g mask="url(#BookIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
BookIcon.nessiePropTypes = iconNessiePropTypes;
BookIcon = nessiePropCheckerWrapper(BookIcon);
export default BookIcon;
