import { ButtonHTMLAttributes, forwardRef } from "react";

type UnstyledButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  inverted?: string;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};
const UnstyledButton = forwardRef<HTMLButtonElement, UnstyledButtonProps>(({ ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    sx={{ backgroundColor: "transparent", border: "none", padding: 0, textAlign: "inherit" }}
    // Do something with the extra props
  />
));

export default UnstyledButton;
