import { getWeeksInMonth } from "@internationalized/date";
import { useCalendarGrid } from "@react-aria/calendar";
import { useLocale } from "@react-aria/i18n";
import { CalendarState, RangeCalendarState } from "@react-stately/calendar";
import { LabelText } from "../../../nessie";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { CalendarCell } from "./CalendarCell";

type CalendarGridProps = {
  state: CalendarState | RangeCalendarState;
};

export function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps} cellPadding="0" sx={gridStyles}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>
              <LabelText kind="secondary">{day}</LabelText>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i: number) => (date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const gridStyles: ThemeUIStyleObject = {
  width: "100%",
  marginTop: "dt_l",

  "tbody:before": {
    lineHeight: 1,
    content: '"."',
    color: "dt_content_light",
    bacgroundColor: "dt_background_primary",
    Display: "block",
  },
};
