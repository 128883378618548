export const nessieColors = {
  transparent: "transparent",
  inherit: "inherit",
  dt_taro0: "#FFFFFF",
  dt_taro10: "#F1F3F8",
  dt_taro20: "#E2E4F0",
  dt_taro30: "#D3D7EC",
  dt_taro40: "#AAB0D8",
  dt_taro50: "#8689B8",
  dt_taro60: "#5D5D8F",
  dt_taro70: "#403F6C",
  dt_taro80: "#2C2A50",
  dt_taro90: "#1A192D",
  dt_taro100: "#000000",

  dt_grape10: "#F6F2FF",
  dt_grape20: "#F0E7FF",
  dt_grape30: "#D5BFFF",
  dt_grape40: "#BA99FF",
  dt_grape50: "#9C6DFF",
  dt_grape60: "#8047FF",
  dt_grape70: "#5E19E9",
  dt_grape80: "#3E11A7",
  dt_grape90: "#26185B",

  dt_blueberry10: "#E8F5FF",
  dt_blueberry20: "#D1EBFF",
  dt_blueberry30: "#A8D8FF",
  dt_blueberry40: "#61B5FF",
  dt_blueberry50: "#408CFF",
  dt_blueberry60: "#2054EE",
  dt_blueberry70: "#1A35BB",
  dt_blueberry80: "#1F2995",
  dt_blueberry90: "#202063",

  dt_aqua10: "#F2FAFF",
  dt_aqua20: "#E1F8FF",
  dt_aqua30: "#B2EEFF",
  dt_aqua40: "#70DBFF",
  dt_aqua50: "#00B2F7",
  dt_aqua60: "#0092E5",
  dt_aqua70: "#046BC6",
  dt_aqua80: "#0C4689",
  dt_aqua90: "#1B2F54",

  dt_kiwi10: "#F2FFF0",
  dt_kiwi20: "#E3F8DC",
  dt_kiwi30: "#BAF694",
  dt_kiwi40: "#83DC1F",
  dt_kiwi50: "#58BC00",
  dt_kiwi60: "#28A100",
  dt_kiwi70: "#1B800C",
  dt_kiwi80: "#0D5206",
  dt_kiwi90: "#153515",

  dt_mango10: "#FFFEE5",
  dt_mango20: "#FCF8C8",
  dt_mango30: "#FDEE7F",
  dt_mango40: "#FFD500",
  dt_mango50: "#F7A500",
  dt_mango60: "#DE7A00",
  dt_mango70: "#B85408",
  dt_mango80: "#7E300E",
  dt_mango90: "#4E241C",

  dt_watermelon10: "#FFF5F6",
  dt_watermelon20: "#FFECEE",
  dt_watermelon30: "#FCD4D8",
  dt_watermelon40: "#FFA6B1",
  dt_watermelon50: "#FF7D7E",
  dt_watermelon60: "#F04F59",
  dt_watermelon70: "#D3304E",
  dt_watermelon80: "#8C1F40",
  dt_watermelon90: "#531D34",
} as const;
